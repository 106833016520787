import {createRouter, createWebHashHistory} from 'vue-router';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      component: () => import('@/layout/DefaultLayout.vue'),
      meta: { title : '星通投放后台'},
      children: [
        { path: 'home', component: () => import('@/views/HomePage.vue') , meta: { keepAlive: true } },
        { path: 'book/bookList', component: () => import('@/views/book/BookList.vue') , meta: { keepAlive: true } },
        { path: 'book/chapterList', component: () => import('@/views/book/ChapterList.vue') , meta: { keepAlive: true }},
        { path: 'promotion/postbackStrategyList', component: () => import('@/views/promotion/PostbackStrategyList.vue') , meta: { keepAlive: true }},
        { path: 'promotion/promotionLinkList', component: () => import('@/views/promotion/PromotionLinkList.vue') , meta: { keepAlive: true }},
        { path: 'operate/payTemplateList', component: () => import('@/views/operate/PayTemplateList.vue') , meta: { keepAlive: true }},
        { path: 'operate/noticeList', component: () => import('@/views/operate/NoticeList.vue') , meta: { keepAlive: true }},
        { path: 'order/orderList', component: () => import('@/views/order/OrderList.vue') , meta: { keepAlive: true }},
        { path: 'distributor/distributorList', component: () => import('@/views/distributor/DistributorList.vue') , meta: { keepAlive: true }},
        { path: 'user/userList', component: () => import('@/views/user/UserList.vue') , meta: { keepAlive: true }},
        { path: 'user/withdrawalApplicationList', component: () => import('@/views/user/WithdrawalApplicationList.vue') , meta: { keepAlive: true }},
        { path: 'system/appInfoList', component: () => import('@/views/system/AppInfoList.vue') , meta: { keepAlive: true }},
        { path: 'system/systemConfig', component: () => import('@/views/system/SystemConfig.vue') , meta: { keepAlive: true }},
        { path: 'web/recommendTypeList', component: () => import('@/views/web/RecommendTypeList.vue') , meta: { keepAlive: true }},
        { path: 'web/recommendRecordList', component: () => import('@/views/web/RecommendRecordList.vue') , meta: { keepAlive: true }},
        { path: 'data/promotionLinkData', component: () => import('@/views/data/PromotionLinkData.vue') , meta: { keepAlive: true }},
        { path: 'data/dataDailySum', component: () => import('@/views/data/DataDailySum.vue') , meta: { keepAlive: true }},
        { path: 'data/hour/promotionLinkHourData', component: () => import('@/views/data/hour/PromotionLinkHourData.vue') , meta: { keepAlive: true }},
        { path: 'data/hour/bookHourData', component: () => import('@/views/data/hour/BookHourData.vue') , meta: { keepAlive: true }},
        { path: 'data/hour/opHourData', component: () => import('@/views/data/hour/OpHourData.vue') , meta: { keepAlive: true }},
        { path: 'data/promotionLinkDayData', component: () => import('@/views/data/PromotionLinkDayData.vue') , meta: { keepAlive: true }},
        { path: 'data/userDayDate', component: () => import('@/views/data/UserDayDate.vue') , meta: { keepAlive: true }},
        { path: 'data/repurchaseData', component: () => import('@/views/data/RepurchaseData.vue') , meta: { keepAlive: true }},
        { path: 'data/bookData', component: () => import('@/views/data/BookData.vue') , meta: { keepAlive: true }},
        { path: 'data/bookDataOcean', component: () => import('@/views/data/BookDataOcean.vue') , meta: { keepAlive: true }},
        { path: 'data/bookDataKs', component: () => import('@/views/data/BookDataKs.vue') , meta: { keepAlive: true }},
        { path: 'data/ksNaData', component: () => import('@/views/data/ksNaData.vue') , meta: { keepAlive: true }},
        { path: 'data/bookFullData', component: () => import('@/views/data/BookFullData.vue') , meta: { keepAlive: true }},
        { path: 'data/promotionV2DayData', component: () => import('@/views/data/PromotionV2DayData.vue') , meta: { keepAlive: true }},
        { path: 'channel/channelList', component: () => import('@/views/channel/ChannelList.vue') , meta: { keepAlive: true }},
        { path: 'channel/channelBookList', component: () => import('@/views/channel/ChannelBookList.vue') , meta: { keepAlive: true }},
        { path: 'promotion/mediaAdList', component: () => import('@/views/promotion/MediaAdList.vue') , meta: { keepAlive: true }},
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/loginPage.vue'),
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;